<template>
    <header>
        <div class="header-sub">
            <img src="../assets/images/team_header.png" alt="" srcset="">

            <div class="header-sub-content">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 text-center">
                            <h1 class="display-1">Privacy <strong>Policy</strong></h1>
                            <hr class="w-25 m-auto"/>
                            <p class="mt-4 mb-4 w-75 m-auto">Privacy policy for customers</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <main class="text-white py-5">
      <div class="privacypolicy container">
          <div class="col-sm-12 lab my-5">
              <h2>1. Informacje ogólne</h2>
              <p>Niniejsza Polityka Prywatności określa zasady przetwarzania i ochrony danych osobowych przekazywanych przez użytkowników podczas korzystania z naszego serwisu internetowego.</p>
          </div>

          <div class="col-sm-12 lab my-5">
              <h2>2. Administrator danych</h2>
              <p>Administratorem danych osobowych jest Grupa Heskor, która jest internetową grupą działającą na zlecenie. Nie posiada fizycznego aresu ani założonej firmy. Są to osoby działające indywidualnie jako Freelancerzy.</p>
          </div>

          <div class="col-sm-12 lab my-5">
              <h2>3. Cele przetwarzania danych</h2>
              <p>Dane osobowe przetwarzane są w celu:</p>
              <ul>
                  <li>- świadczenia usług dostępnych w ramach serwisu,</li>
                  <li>- obsługi zapytań i kontaktu z użytkownikami,</li>
                  <li>- personalizacji treści i reklam,</li>
                  <li>- prowadzenia analiz statystycznych.</li>
                  <li>- kontaktu z Klientem w celu przeprowadzenia działań nad zleconym projektem</li>
              </ul>
          </div>

          <div class="col-sm-12 lab my-5">
              <h2>4. Podstawa prawna przetwarzania danych</h2>
              <p>Podstawą prawną przetwarzania danych osobowych jest:</p>
              <ul>
                  <li>- niezbędność do wykonania umowy, której stroną jest użytkownik,</li>
                  <li>- niezbędność do wypełnienia obowiązku prawnego ciążącego na administratorze,</li>
                  <li>- uzyskanie zgody od użytkownika.</li>
              </ul>
          </div>

          <div class="col-sm-12 lab my-5">
              <h2>5. Rodzaje przetwarzanych danych</h2>
              <p>Przetwarzamy następujące kategorie danych osobowych:</p>
              <ul>
                  <li>- dane identyfikacyjne,</li>
                  <li>- dane kontaktowe,</li>
                  <li>- dane dotyczące korzystania z serwisu.</li>
              </ul>
          </div>

          <div class="col-sm-12 lab my-5">
              <h2>6. Okres przechowywania danych</h2>
              <p>Dane osobowe przechowywane są przez okres niezbędny do realizacji celów, dla których zostały zebrane, z uwzględnieniem obowiązujących przepisów prawa.</p>
          </div>

          <div class="col-sm-12 lab my-5">
              <h2>7. Prawa użytkowników</h2>
              <p>Użytkownicy serwisu mają prawo do:</p>
              <ul>
                  <li>- dostępu do swoich danych,</li>
                  <li>- sprostowania swoich danych,</li>
                  <li>- usunięcia swoich danych,</li>
                  <li>- dostępu do swoich danych,</li>
                  <li>- ograniczenia przetwarzania swoich danych,</li>
                  <li>- przenoszenia swoich danych,</li>
                  <li>- wniesienia sprzeciwu wobec przetwarzania danych.</li>
              </ul>
          </div>

          <div class="col-sm-12 lab my-5">
              <h2>8. Bezpieczeństwo danych</h2>
              <p>Działamy w oparciu o nowoczesne rozwiązania techniczne i organizacyjne w celu zabezpieczenia przetwarzanych danych osobowych przed nieuprawnionym dostępem.</p>
          </div>

          <div class="col-sm-12 lab my-5">
              <h2>9. Cookies</h2>
              <p>Nasz serwis wykorzystuje pliki cookies. Więcej informacji na temat cookies znajduje się w naszej Polityce Cookies.</p>
          </div>

          <div class="col-sm-12 lab my-5">
              <h2>10. Kontakt</h2>
              <p>W sprawach związanych z przetwarzaniem danych osobowych oraz korzystaniem z praw użytkowników, prosimy o kontakt za pośrednictwem <a href="mailto:contact@heskor.com">contact@heskor.com</a>.</p>
          </div>

      </div>
    </main>

</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'PrivacyPolicy',
    beforeRouteEnter(to, from, next) {
        const { title, desc } = to.meta;

        document.title = title;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute('content', desc);
        }

        next();
    },
}
</script>
<template>
    <header>
        <div class="header-sub ">
            <img src="../assets/images/projects_header.png" alt="" srcset="">

            <div class="header-sub-content">
                <div class="container">
                    <div class="row ">
                        <div class="col-sm-6">
                            <h1 class="letter-spacing"><strong>Portfolio WWW</strong></h1>
                            <hr class="w-25"/>
                            <p class="mt-4 mb-4 w-75">Strony internetowe wykonane dla Klientów z przeróżnych branż</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <main class="text-white">
        <div class="projects-bar py-4">
            <div class="container">
              <div class="row">
                  <div class="col-sm-3 text-center my-2">
                    <div class="projects-box border border-2 border-primary">
                        <p class="h2">07</p>
                        <strong class="letter-spacing">Ukończonych projekty</strong>
                    </div>
                  </div>

                  <div class="col-sm-3 text-center my-2">
                      <div class="projects-box border border-2 border-primary">
                          <p class="h2">06+</p>
                          <strong class="letter-spacing">Klientów</strong>
                      </div>
                  </div>

                  <div class="col-sm-3 text-center my-2">
                      <div class="projects-box border border-2 border-primary">
                          <p class="h2">10y+</p>
                          <strong class="letter-spacing">Doświadczenia</strong>
                      </div>
                  </div>

                  <div class="col-sm-3 text-center my-2">
                      <div class="projects-box border border-2 border-primary">
                          <p class="h2">02</p>
                          <strong class="letter-spacing">Team</strong>
                      </div>
                  </div>

              </div>
            </div>
        </div>

        <div class="projects container py-5">
            <div class="row">

                <div class="project-item row d-flex align-items-center py-5 px-4">
                    <div class="col-sm-6"><img src="../assets/images/projects/po_heskor.png" width="100%" alt="" /></div>
                    <div class="col-sm-6">
                        <strong class=" text-uppercase">Strona internetowa</strong>
                        <p class="h3 text-uppercase">Heskor</p>
                        <p><b>Opis projektu:</b> Strona internetowa oparta o framework Vue.js, wykonana na potrzeby grupy Heskor. Dodatkowo posiadająca własny system CRM.</p>
                        <p>Zobacz projekt: <a href="#" target="_blank" rel="nofollow" class="text-decoration-none">Click</a></p>
                        <div class="row">
                            <div class="col-sm-12 p-0">
                                <span class="border border-2 border-primary rounded p-1 px-2 mx-2">HTML</span>
                                <span class="border border-2 border-primary rounded p-1 px-2 mx-2">CSS</span>
                                <span class="border border-2 border-primary rounded p-1 px-2 mx-2">Vue.js</span>
                                <span class="border border-2 border-primary rounded p-1 px-2 mx-2">JavaScript</span>
                            </div>
                        </div>
                    </div>
                </div>

                <hr/>

                <div class="project-item row d-flex align-items-center py-5 px-4">
                    <div class="col-sm-6">
                        <strong class="text-uppercase">Strona internetowa</strong>
                        <p class="h3 text-uppercase">Order Hunters</p>
                        <p><b>Opis projektu:</b> Strona stworzona dla firmy spedycyjnej na potrzeby zbudowania bardziej rozpoznawalnej marki firmy.</p>
                        <p>Zobacz projekt: <a href="https://order-hunters.com" target="_blank" rel="nofollow" class="text-decoration-none">Click</a></p>
                        <div class="row">
                            <div class="col-sm-12">
                                <span class="border border-2 border-primary rounded p-1">Wordpress</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <img src="../assets/images/projects/po_orderhunters.png" width="100%" alt="" />
                    </div>
                </div>




                <hr/>

                <div class="project-item row d-flex align-items-center py-5 px-4">
                    <div class="col-sm-6"><img src="../assets/images/projects/po_parafiakuslina.png" width="100%" alt="" /></div>
                    <div class="col-sm-6">
                        <strong class=" text-uppercase">Strona internetowa</strong>
                        <p class="h3 text-uppercase">Parafia Kuślin</p>
                        <p><b>Opis projektu:</b> Projekt polegał na stworzeniu front-endu dla Kościoła. Dodatkowo wymagana była synchronizacja z całym systemem CMS, który został ręcznie napisany przy współpracy z innym freelancerem.</p>
                        <p>Zobacz projekt: <a href="https://parafiakuslin.pl" target="_blank" rel="nofollow" class="text-decoration-none">Click</a></p>
                        <div class="row">
                            <div class="col-sm-12 p-0">
                                <span class="border border-2 border-primary rounded p-1 px-2 mx-2">HTML</span>
                                <span class="border border-2 border-primary rounded p-1 px-2 mx-2">CSS</span>
                                <span class="border border-2 border-primary rounded p-1 px-2 mx-2">PHP</span>
                                <span class="border border-2 border-primary rounded p-1 px-2 mx-2">JavaScript</span>
                            </div>
                        </div>
                    </div>
                </div>

                <hr/>

                <div class="project-item row d-flex align-items-center py-5 px-4">
                    <div class="col-sm-6">
                        <strong class=" text-uppercase">Strona internetowa</strong>
                        <p class="h3 text-uppercase">Artrans</p>
                        <p><b>Opis projektu:</b> Celem projektu było napisanie całego front-endu oraz jego synchronizacją z już utworzonym systemem CMS opartym o PHP i frameworkiem Symfony.</p>
                        <p>Zobacz projekt: <a href="https://artrans.ovh" target="_blank" rel="nofollow" class="text-decoration-none">Click</a></p>
                        <div class="row">
                            <div class="col-sm-12 p-0">
                                <span class="border border-2 border-primary rounded p-1 px-2 mx-2">HTML</span>
                                <span class="border border-2 border-primary rounded p-1 px-2 mx-2">CSS</span>
                                <span class="border border-2 border-primary rounded p-1 px-2 mx-2">PHP</span>
                                <span class="border border-2 border-primary rounded p-1 px-2 mx-2">JavaScript</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <img src="../assets/images/projects/po_artrans.png" width="100%" alt="" />
                    </div>
                </div>

                <hr/>

                <div class="project-item row d-flex align-items-center py-5 px-4">
                    <div class="col-sm-6"><img src="../assets/images/projects/po_moodway.png" width="100%" alt="" /></div>
                    <div class="col-sm-6">
                        <strong class=" text-uppercase">Strona internetowa</strong>
                        <p class="h3 text-uppercase">Moodway</p>
                        <p><b>Opis projektu:</b> Projekt strony internetowej został utworzony na systmie CMS WordPress dla agencji kreatywnej, na potrzeby rozbudowy świadomości marki w internecie.</p>
                        <p>Zobacz projekt: <a href="#" target="_blank" rel="nofollow" class="text-decoration-none">Click</a></p>
                        <div class="row">
                            <div class="col-sm-12">
                                <span class="border border-2 border-primary rounded p-1">Wordpress</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </main>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Contact',
    beforeRouteEnter(to, from, next) {
        // Odczytaj metadane z to.meta
        const { title, desc } = to.meta;

        // Ustaw metadane w nagłówkach strony
        document.title = title;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute('content', desc);
        }

        next();
    },
}
</script>
import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';
import AboutUs from '@/views/About.vue';
import Team from '@/views/Team.vue';
import Projects from "@/views/Projects.vue";
import Contact from '@/views/Contact.vue';
import PrivacyPolicy from '@/views/PrivacyPolicy.vue';
import PageNotFound from '@/views/PageNotFound.vue';
import WebDevelopment from "@/views/WebDevelopment.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            title: 'Aplikacje i strony internetowe, SEO, UX/UI dla firm | Heskor',
            desc: 'Heskor to grupa freelancerów tworząca projekty stron www, aplikacji internetowych, działająca i znająca się w dziedzinie marketingu oraz UX/UI',
        },
    },
    {
        path: "/webdevelopment",
        name: "WebDevelopment",
        component: WebDevelopment,
        meta: {
            title: 'Strony internetowe oparte o systemy CMS | Heskor',
            desc: 'Strona oparta o system CMS, to strona www, która w szybki i łatwy sposób pomoże Tobie lub Firmie w jej rozwoju!',
        },
    },
    {
        path: "/about",
        name: "About",
        component: AboutUs,
        meta: {
            title: 'Freelancer, programista, grafik, kim jesteśmy? | Heskor',
            desc: 'Grupa freelancerów, tworząca z pasji do tego czym się zajmuje. Tworzymy innowacyjne projekty IT i nie tylko!',
        },
    },
    {
        path: "/team",
        name: "Team",
        component: Team,
        meta: {
            title: 'Zespół freelancerów | Heskor',
            desc: 'Czy potrzebujesz freelancera? Skontaktuj się z nami, a przekażemy odpowiednie informacje naszemu projektantowi, z którym możesz współpracować!',
        },
    },
    {
        path: "/projects",
        name: "Projects",
        component: Projects,
        meta: {
            title: 'Projekty stron internetowych, www, portfolio | Heskor',
            desc: 'Strony internetowe na zamówienie w Heskor! To dobry wybór na stworzenie Twojej pierwszej lub kolejnej witryny www. Pomożemy także w jej optymalizacji i świadomości w internecie!',
        },
    },
    {
        path: "/contact",
        name: "Contact",
        component: Contact,
        meta: {
            title: 'Kontakt, darmowa wycena projektu IT | Heskor',
            desc: 'Heskor to grupa ludzi (freelancerów) z wieloletnim doświadczeniem w branży, tworząca różne projekty z dziedziny grafiki, stron internetowych oraz aplikacji.',
        },
    },
    {
        path: "/privacypolicy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
        meta: {
            title: 'Polityka prywatności | Heskor',
            desc: 'Polityka prywatności - Heskor',
        },
    },
    // Error Page
    {
        path: '/:catchAll(.*)',
        name: 'PageNotFound',
        component: PageNotFound,
        meta: {
            title: '404 Error | Heskor',
            desc: '',
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
<template>
    <main class="text-white">
        <!-- header -->
        <header>
            <div class="header-sub">
                <img src="../assets/images/img-recovery/man-with-copy-space-futuristic-device.jpg" alt="" srcset="">

                <div class="header-sub-content">
                    <div class="container">
                        <div class="row ">
                            <div class="col-sm-12 text-center">
                                <h1 class="display-1">Strony Internetowe</h1>
                                <hr class="w-25 m-auto"/>
                                <p class="mt-4 mb-4 w-75 m-auto">Stworzymy stronę internetową dla Ciebie i Twojej firmy!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>



        <!-- web development -->
        <div class="section-webdevelopment py-5">
            <div class="container text-center text-sm-start">
                <div class="row">


                    <div class="container">
                        <div class="row d-flex align-items-center py-5">
                            <div class="col-sm-5">
                                <h2>Strona internetowa dla Twojej firmy to wizytówka dla Ciebie!</h2>
                                <p class="text-basic-color">Koncentrujemy się na obsłudze każej wielkości firm. Nasze usługi internetowe są dostosowane do potrzeb i celów klienta!</p>

                                <div class="row">
                                    <div class="col-sm-1"><img src="../assets/images/icons/check-solid.svg" width="32px" alt="" /></div>
                                    <div class="col-sm-11">
                                        <p class="h4">Kompleksowo i profesjonalnie</p>
                                        <p class="text-basic-color">Wysokiej jakości projektowanie, tworzenie, wdrażanie i wsparcie techniczne stron internetowych.</p>
                                    </div>
                                    <div class="col-sm-1"><img src="../assets/images/icons/check-solid.svg" width="32px" alt="" /></div>
                                    <div class="col-sm-11">
                                        <p class="h4">Nowoczesny styl</p>
                                        <p class="text-basic-color">Strona dostosowana i zgodna z zasadami (RWD), co oznacza responsywną witrynę dla Twojej firmy na urządzeniach mobilnych.</p>
                                    </div>
                                    <div class="col-sm-1"><img src="../assets/images/icons/check-solid.svg" width="32px" alt="" /></div>
                                    <div class="col-sm-11">
                                        <p class="h4">Dostęp do panelu CMS</p>
                                        <p class="text-basic-color">Po zakończeniu projektu wszystkie dane zostaną przeniesione do panelu, z którego można obsługiwać i zarządzać treścią i innymi elementami witryny.</p>
                                    </div>
                                    <div class="col-sm-1"><img src="../assets/images/icons/check-solid.svg" width="32px" alt="" /></div>
                                    <div class="col-sm-11">
                                        <p class="h4">Bezpłatne wsparcie</p>
                                        <p class="text-basic-color">Miesięczne bezpłatne wsparcie techniczne, umożliwiające zadawanie nam pytań i wprowadzanie drobnych zmian.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-7 text-center">
                                <img src="../assets/images/4906463.jpg" width="80%" class="my-3 rounded-4" alt="" />
                            </div>
                        </div>
                    </div>
                    <div class="container py-5">
                        <div class="row d-flex align-items-center justify-content-center text-center">
                            <div class="col-sm-12">
                                <p class="h2">Zainteresowały Cię nasze usługi? <strong>Napisz do nas już dziś!</strong></p>
                                <p>Przeanalizujemy dla Ciebie projekt i przedstawimy wycenę zgodną z Twoimi celami!</p>
                                <a href="/contact"><button class="button">
                                    <i class="fa-solid fa-paper-plane fa-bounce fa-lg"></i> Bezpłatna konsultacja
                                </button></a>
                            </div>
                        </div>
                    </div>

                    <hr/>

                    <div class="py-5  my-5 px-4">
                        <div class="col-sm-12 text-center">
                            <p class="h2">Z jakimi systemami <strong>CMS</strong> współpracujemy?</p>
                            <p class="text-basic-color">Pomożemy Ci wybrać system odpowiedni do Twoich potrzeb!</p>
                        </div>

                        <div class="row justify-content-center">
                            <div class="col-sm-12">
                                <div class="row justify-content-center">
                                    <div class="col-sm-1 text-center item-box-visable m-2 d-flex flex-column justify-content-center align-items-center">
                                        <i class="fa-brands fa-wordpress fa-2xl p-3 mb-2"></i>
                                        <p class="m-0">WordPress</p>
                                    </div>
                                    <div class="col-sm-1 text-center item-box-visable m-2 d-flex flex-column justify-content-center align-items-center">
                                        <i class="fa-brands fa-joomla fa-2xl p-3 mb-2"></i>
                                        <p class="m-0">Joomla</p>
                                    </div>
                                    <div class="col-sm-1 text-center item-box-visable m-2 d-flex flex-column justify-content-center align-items-center">
                                        <img src="../assets/images/logo-programs/prestashop.svg" class="mb-2" width="32px" alt="" />
                                        <p class="m-0">PrestaShop</p>
                                    </div>
                                    <div class="col-sm-1 text-center item-box-visable m-2 d-flex flex-column justify-content-center align-items-center">
                                        <img src="../assets/images/icons/paper-plane-solid.svg" class="mb-2" width="32px" alt="" />
                                        <p class="m-0">Shoper</p>
                                    </div>
                                    <div class="col-sm-1 text-center item-box-visable m-2 d-flex flex-column justify-content-center align-items-center">
                                        <img src="../assets/images/logo-programs/wix.svg" class="mb-2" width="32px" alt="" />
                                        <p class="m-0">Wix</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <hr/>

                    <div class="container">
                        <div class="row d-flex align-items-center justify-content-center py-5">
                            <div class="col-sm-6">
                                <img src="../assets/images/webdevelopment_cms.jpg" class="my-3 rounded-4" width="100%" alt="" />
                            </div>
                            <div class="col-sm-6">
                                <h2>Strona internetowa oparta na CMS (systemie zarządzania treścią)</h2>
                                <p class="text-basic-color">CMS, czyli Content Management System, to specjalne oprogramowanie, które umożliwia tworzenie, edytowanie, organizowanie i publikowanie treści na stronie internetowej za pomocą prostego panelu użytkownika/administratora.</p>
                                <p><strong>1. Intuicyjny panel administracyjny:</strong> System, w którym można łatwo edytować elementy witryny lub organizować zawartość za pomocą przyjaznego dla użytkownika interfejsu i edytora.</p>
                                <p><strong>2. Planowanie i edycja treści:</strong> Po zalogowaniu się do panelu administracyjnego możemy łatwo dodawać nowe artykuły do bloga lub zmieniać zawartość na określonej stronie.</p>
                                <p><strong>3. Szablony i motywy:</strong> Gotowe szablony przygotowane z wyprzedzeniem lub stworzone przez nas, które można łatwo zmienić w dowolnym momencie.</p>
                                <p><strong>4. Bezpieczeństwo i aktualizacje:</strong> Systemy CMS są regularnie aktualizowane pod kątem bezpieczeństwa, pomagając chronić witrynę i jej zawartość.</p>
                                <p><strong>5. Dodatkowe funkcje:</strong> Witrynę można łatwo modyfikować i dodawać nowe funkcje za pomocą wtyczek lub modułów. Na przykład, jeśli początkowo rozważasz tylko stronę internetową, a później chcesz dodać sklep internetowy, nie stanowi to problemu.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </div>

    </main>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'WebDevelopment',
    beforeRouteEnter(to, from, next) {
        // Odczytaj metadane z to.meta
        const { title, desc } = to.meta;

        // Ustaw metadane w nagłówkach strony
        document.title = title;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute('content', desc);
        }

        next();
    },
}
</script>
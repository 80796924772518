<template>
    <main class="text-white py-5">
        <div class="container py-5">
            <div class="row py-5">
                <div class="col-sm-5">
                    <h2>Heskor. <strong>Jesteśmy tutaj by Ci pomóc.</strong></h2>
                    <p class="py-4">Zanim zaczniemy, chcielibyśmy lepiej zrozumieć Twoje potrzeby. Zapoznamy się z Twoim zgłoszeniem i umówimy się na bezpłatną wycenę.</p>
                    <p class="h5 py-4 ">Wyślij swój pomysł już dziś! Skontaktujemy się z Tobą bezpłatnie!</p>
                </div>
                <div class="col-sm-7">
                    <div class="bg-danger my-3 text-center text-sm-start ">!! Obecnie prosimy o wysyłanie wiadomości e-mail tutaj: <b><a href="mailto:contact@heskor.com" class="text-white">contact@heskor.com</a></b> !!</div>
                    <form>
                        <div class="row">
                            <div class="col-sm-6 mb-3">
                                <label for="firstName" class="form-label">Imię*</label>
                                <input type="text" class="form-control" id="firstName" placeholder="">
                            </div>
                            <div class="col-sm-6 mb-3">
                                <label for="lastName" class="form-label">Nazwisko*</label>
                                <input type="text" class="form-control" id="lastName" placeholder="">
                            </div>

                            <div class="col-sm-6 mb-3">
                                <label for="email" class="form-label">Email*</label>
                                <input type="email" class="form-control" id="email" placeholder="">
                            </div>
                            <div class="col-sm-6 mb-3">
                                <label for="phone" class="form-label">Nr. Telefonu</label>
                                <input type="tel" class="form-control" id="telefon" placeholder="">
                            </div>

                            <div class="col-sm-6 mb-3">
                                <label for="companyName" class="form-label">Nazwa firmy</label>
                                <input type="text" class="form-control" id="companyName" placeholder="">
                            </div>

                            <div class="col-sm-12 mb-3">
                                <label for="message" class="form-label ">Opowiedz nam o swoim projekcie*</label>
                                <textarea class="form-control" id="message" rows="4" placeholder=""></textarea>
                            </div>

                            <div class="col-sm-12 mb-3">
                                <input type="checkbox" class="form-check-input" id="zgodaMarketingowa">
                                <label class="form-check-label" style="font-size: 12px; margin-left: 5px;" for="zgodaMarketingowa">Wyrażam zgodę na przetwarzanie moich danych osobowych w celu kontaktu oraz weryfikacji projektu przez grupę Heskor.</label>
                            </div>

                            <button type="submit" class="button letter-spacing">Zamów bezpłatną wycenę</button>
                        </div>

                    </form>
                </div>
            </div>
        </div>

    </main>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Contact',
    beforeRouteEnter(to, from, next) {
        const { title, desc } = to.meta;

        document.title = title;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute('content', desc);
        }

        next();
    },
}
</script>
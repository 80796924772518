<template>
    <div>
        <h1>Strona nie istnieje</h1>
        <p>Przepraszamy, ale strona, której szukasz, nie istnieje.</p>
    </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'PageNotFound',
    beforeRouteEnter(to, from, next) {
        // Odczytaj metadane z to.meta
        const { title, desc } = to.meta;

        // Ustaw metadane w nagłówkach strony
        document.title = title;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute('content', desc);
        }

        next();
    },
}
</script>

<template>
    <nav class="navbar navbar-expand-lg navbar-dark fixed-top">
        <div class="container-fluid container">

            <a class="navbar-brand" href="/"><img src="../assets/images/heskor-white.svg"/></a>

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText"
                    aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <!-- Menu -->
            <div class="collapse navbar-collapse justify-content-center" id="navbarText">
                <ul class="navbar-nav">
                    <li class="nav-item dropdown px-2">
                        <a class="nav-link dropdown-toggle" href="#" id="projectsDropdown" role="button" data-bs-toggle="dropdown"
                           aria-expanded="false">Oferta</a>
                        <ul class="dropdown-menu" aria-labelledby="projectsDropdown">
                            <li><a class="dropdown-item" href="/webdevelopment">Strony internetowe</a></li>
                            <li><a class="dropdown-item" href="#">Aplikacje internetowe</a></li>
                            <li><a class="dropdown-item" href="#">UI Design</a></li>
                            <li><a class="dropdown-item" href="#">UX Design</a></li>
                            <li><a class="dropdown-item" href="#">Outsourcing</a></li>
                        </ul>
                    </li>
                    <li class="nav-item px-2"><a href="/about" class="nav-link">Kim jesteśmy</a></li>
                    <li class="nav-item dropdown px-2">
                        <a class="nav-link dropdown-toggle" href="#" id="projectsDropdown" role="button" data-bs-toggle="dropdown"
                           aria-expanded="false">Projekty</a>
                        <ul class="dropdown-menu" aria-labelledby="projectsDropdown">
                            <li><a class="dropdown-item" href="/projects">Projekty WWW</a></li>
                            <li><a class="dropdown-item" href="#">Projekty aplikacji internetowych</a></li>
                            <li><a class="dropdown-item" href="#">Projekty UX/UI</a></li>
                        </ul>
                    </li>
                    <li class="nav-item px-2"><a href="/team" class="nav-link">Team</a></li>
                </ul>
            </div>

            <div class="ml-auto">
                <ul class="navbar-nav form-inline my-2 my-lg-0">
                    <li class="nav-item"><a href="/contact" class="nav-link active-contact">Kontakt</a></li>
                </ul>
            </div>
        </div>
    </nav>

</template>
<script>
export default {
    methods: {
        changeLanguage(locale) {
            this.$i18n.locale = locale;
        },
    },
};
// Add an event listener to the window for scroll events
window.addEventListener('scroll', function () {
    // Get the navbar element
    var navbar = document.querySelector('.navbar');

    // Add the 'navbar-scrolled' class when scrolling down
    if (window.scrollY > 0) {
        navbar.classList.add('navbar-scrolled');
    } else {
        // Remove the 'navbar-scrolled' class when at the top
        navbar.classList.remove('navbar-scrolled');
    }
});

</script>
<template>
    <main class="text-white">
        <!-- header -->
        <header>
            <div class="header-sub">
                <img src="../assets/images/aboutus_header.jpg" alt="" srcset="">

                <div class="header-sub-content">
                    <div class="container">
                        <div class="row ">
                            <div class="col-sm-12 text-center">
                                <h1 class="display-1">Poznaj nas i <strong>stwórz projekt</strong> z doświadczonymi specjalistami</h1>
                                <hr class="w-25 m-auto"/>
                                <p class="mt-4 mb-4 w-75 m-auto">Tworzymy projekty dla osób indywidualnych oraz firm z całego świata!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>



        <!-- about us -->
        <div class="about-us py-5">
            <div class="container text-center text-sm-start">
                <div class="row">
                    <div class="col-sm-6 py-5">
                        <h2>Projekty tworzone <strong>na zamówienie</strong> dla Twojej firmy!</h2>
                    </div>
                    <div class="col-sm-6">
                        <div class="about-us-desc py-5">
                            <p class="text-basic-color"><b>Heskor</b> to grupa (Freelancerów) działających w branży już od wielu lat. Tworzymy projekty cyfrowe dla firm oraz osób indywidualnych z całej Polski oraz Świata. Dostarczamy także nasze projekty i aplikacje do usprawnienia procesów w firmach i nie tylko! Wspólnie działamy od 2023 roku, jednak nasze doświadczenie zawodowe sięga o wiele dalej.</p>
                        </div>
                    </div>





                    <div class="container">
                        <div class="row d-flex align-items-center justify-content-center py-5">
                            <div class="col-sm-6">
                                <img src="../assets/images/aboutus_AI1.jpg" class="my-3 rounded-4" width="100%" alt="" />
                            </div>
                            <div class="col-sm-6">
                                <h2>Tworzymy innowacyjne projekty oparte o najnowsze <b>Technologie</b></h2>
                                <p class="text-basic-color">Tworzymy różnego rodzaju projekty związane z oprogramowaniem, stronami internetowymi, grafiką czy AI. Nasi specjaliści wykorzystują najnowsze technologie do tworzenia przeróżnych projektów. Tworzymy je indywidualnie lub pod banderą "Heskor".</p>
                            </div>
                        </div>
                    </div>

                        <hr/>

                        <div class="about-us-technology py-5  my-5 px-4">
                            <div class="col-sm-12 text-center">
                                <p class="h1">Używamy <strong>najnowszych technologii</strong></p>
                                <p class="text-basic-color">Każdy projekt posiada dostosowane i najlepsze technologie używane na rynku, uwzględniając potrzeby projektu.</p>
                            </div>

                            <div class="row justify-content-center">
                                <div class="col-sm-12">
                                    <div class="row justify-content-center">
                                        <div class="col-sm-1 text-center item-box-visable m-2 d-flex flex-column justify-content-center align-items-center">
                                            <i class="fa-brands fa-vuejs fa-2xl p-3 mb-2"></i>
                                            <p class="m-0">Vue.js</p>
                                        </div>
                                        <div class="col-sm-1 text-center item-box-visable m-2 d-flex flex-column justify-content-center align-items-center">
                                            <i class="fa-brands fa-js fa-2xl p-3 mb-2"></i>
                                            <p class="m-0">JavaScript</p>
                                        </div>
                                        <div class="col-sm-1 text-center item-box-visable m-2 d-flex flex-column justify-content-center align-items-center">
                                            <i class="fa-brands fa-php fa-2xl p-3 mb-2"></i>
                                            <p class="m-0">PHP</p>
                                        </div>
                                        <div class="col-sm-1 text-center item-box-visable m-2 d-flex flex-column justify-content-center align-items-center">
                                            <i class="fa-brands fa-css3 fa-2xl p-3 mb-2"></i>
                                            <p class="m-0">CSS/SCSS</p>
                                        </div>
                                        <div class="col-sm-1 text-center item-box-visable m-2 d-flex flex-column justify-content-center align-items-center">
                                            <i class="fa-brands fa-react fa-2xl p-3 mb-2"></i>
                                            <p class="m-0">React.js</p>
                                        </div>
                                        <div class="col-sm-1 text-center item-box-visable m-2 d-flex flex-column justify-content-center align-items-center">
                                            <i class="fa-brands fa-figma fa-2xl p-3 mb-2"></i>
                                            <p class="m-0">Figma</p>
                                        </div>
                                        <div class="col-sm-1 text-center item-box-visable m-2 d-flex flex-column justify-content-center align-items-center">
                                            <i class="fa-brands fa-jira fa-2xl p-3 mb-2"></i>
                                            <p class="m-0">Jira</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <hr/>

                    <div class="container">
                        <div class="row d-flex align-items-center justify-content-center py-5">
                            <div class="col-sm-6">
                                <h2>Po więcej projektów zapraszamy na nasze <b>social media</b></h2>
                                <p class="text-basic-color">Na co dzień pracujemy, działamy i tworzymy. Dodatkowo jednak budujemy swoje portfolio i rozwiejmy brand Heskor o nowe horyzonty, pomagamy się rozwinąć oraz zbudować społeczność podzielającą wspólną pasję i zainteresowania.</p>
                            </div>
                            <div class="col-sm-6">
                                <img src="../assets/images/aboutus_AI2.jpg" width="100%" class="my-3 rounded-4" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </main>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'About',
    beforeRouteEnter(to, from, next) {
        // Odczytaj metadane z to.meta
        const { title, desc } = to.meta;

        // Ustaw metadane w nagłówkach strony
        document.title = title;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute('content', desc);
        }

        next();
    },
}
</script>
<template>
        <NavBar />
        <router-view></router-view>
        <FooterPage />
</template>

<script>
import NavBar from './components/NavBar.vue';
import FooterPage from "./components/FooterPage.vue";

export default {
    name: 'App',
    components: {
        NavBar,
            FooterPage,
    }
}
</script>
// i18n.js

import { createI18n } from 'vue-i18n';

const i18n = createI18n({
    legacy: false,
    locale: 'en',
    messages: {
        en: {
            welcome: 'Welcome to our website!',
        },
        pl: {
            welcome: 'Witaj na naszej stronie!',
        },
    },
});

export default i18n;

<template>
    <header>
        <div class="header-sub">
            <img src="../assets/images/team_header.png" alt="" srcset="">

            <div class="header-sub-content">
                <div class="container">
                    <div class="row ">
                        <div class="col-sm-12 text-center">
                            <h1 class="display-1">Poznaj nasz zespół <strong>Heskor</strong></h1>
                            <hr class="w-25 m-auto"/>
                            <p class="mt-4 mb-4 w-75 m-auto text-basic-color">Wspólnie tworzymy innowacyjne projekty technologiczne!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <main class="text-white py-5">

        <div class="team container">
            <div class="row">
                <div class="col-sm-6 py-5">
                    <div class="row">
                        <div class="col-sm-6 text-center my-auto d-block">
                            <img src="https://cdn.leonardo.ai/users/7ef1d18b-2cef-40e2-8b4b-b53ea0d2a03a/generations/d73287a4-c7f8-4274-9f99-1d966f0ea183/variations/Default_avatar_with_a_spiky_small_beard_white_background_tshir_4_d73287a4-c7f8-4274-9f99-1d966f0ea183_1.jpg?w=512" class="rounded-circle" width="200px" alt="" />
                        </div>
                        <div class="col-sm-6 text-center text-sm-start">
                            <h3 class="letter-spacing">Karol (K.)</h3>
                            <p><strong>Founder | PM | Full-Stack Developer</strong></p>
                            <p class="text-basic-color">Twórca oraz Freelancer Heskor. Posiadający wieloletnie doświadczenie w tworzeniu stron, aplikacji oraz marketingu internetowym.</p>
                            <a href="mailto:kkornosz@heskor.com" class="px-1"><i class="fa-solid fa-paper-plane fa-lg"></i></a>
                            <a href="https://www.linkedin.com/in/karol-kornosz/" rel="nofollow" target="_blank" class="px-1"><i class="fa-brands fa-linkedin-in fa-lg"></i></a>
                            <a href="https://www.instagram.com/heskorcom" rel="nofollow" target="_blank" class="px-1"><i class="fa-brands fa-instagram fa-lg"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 py-5">
                    <div class="row">
                        <div class="col-sm-6 text-center">
                            <img src="https://cdn.leonardo.ai/users/7ef1d18b-2cef-40e2-8b4b-b53ea0d2a03a/generations/d73287a4-c7f8-4274-9f99-1d966f0ea183/variations/Default_avatar_with_a_spiky_small_beard_white_background_tshir_4_d73287a4-c7f8-4274-9f99-1d966f0ea183_1.jpg?w=512" class="rounded-circle" width="200px" alt="" />
                        </div>
                        <div class="col-sm-6 text-center text-sm-start">
                            <h3 class="letter-spacing">Daniel (H.)</h3>

                            <p><strong>Graphic Designer</strong></p>
                            <p class="text-basic-color">Doświadczony Grafik komputerowy umiejący zrobić w tym świecie wszystko, co jest niemożliwe. Posiadający także wiedzę z zakresu modelarstwa.</p>
                            <a href="mailto:contact@heskor.com" class="px-1"><i class="fa-solid fa-paper-plane fa-lg"></i></a>
                            <a href="#" rel="nofollow" target="_blank" class="px-1"><i class="fa-brands fa-linkedin-in fa-lg"></i></a>
                            <a href="https://www.instagram.com/heskorcom" rel="nofollow" target="_blank" class="px-1"><i class="fa-brands fa-instagram fa-lg"></i></a>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row py-5 my-5">
                <div class="col-sm-6 py-3">
                    <img src="../assets/images/joinus_heskor.png" width="100%" alt="" />
                </div>
                <div class="col-sm-6 my-auto d-block">
                    <p class="h2 "><strong>Dołącz do nas!</strong></p>
                    <p class="text-basic-color">Poszukujesz ludzi ze środowiska IT? Serdecznie zapraszamy! Przejdziesz u nas proces rekrutacji oraz szkolenia podstawowe z zakresu tego jak działamy. Dodatkowo w przyszłości planujemy wspólne spotkania offline oraz ciągły rozwój marki Heskor. </p>
                    <a href="mailto:contact@hesrko.com"><input type="button" class="button my-sm-4 " value="Wyślij swoje CV!"></a>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Contact',
    beforeRouteEnter(to, from, next) {
        // Odczytaj metadane z to.meta
        const { title, desc } = to.meta;

        // Ustaw metadane w nagłówkach strony
        document.title = title;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute('content', desc);
        }

        next();
    },
}
</script>
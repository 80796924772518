import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n';

import './assets/main.css'
import 'bootstrap/dist/css/bootstrap.css'
import "bootstrap/dist/js/bootstrap.js"



createApp(App).use(router).use(i18n).mount('#app')


<template>
    <footer>
        <div class="container ">
            <div class="row">
                <div class="col-sm-2 text-center text-sm-start">
                    <p class="h5 py-2">Nawigacja</p>
                    <a href="/"><p>Home</p></a>
                    <a href="/about"><p>Kim jesteśmy</p></a>
                    <a href="/projects"><p>Projekty</p></a>
                    <a href="#"><p>Oferta</p></a>
                    <a href="/contact"><p>Kontakt</p></a>
                </div>
                <div class="col-sm-2 text-center text-sm-start">
                    <p class="h5 py-2">Oferta</p>
                    <a href="#"><p>Strony internetowe</p></a>
                    <a href="#"><p>Aplikacje interntowe</p></a>
                    <a href="#"><p>UX/UI</p></a>
                    <a href="#"><p>Grafika komputerowa</p></a>
                    <a href="#"><p>Outsourcing</p></a>
                </div>

                <div class="col-sm-4 rounded my-auto d-block text-center">
                    <img src="../assets/images/heskor-white.svg" class="mx-2" width="180px" alt="" />
                    <p class="text-basic-color">Freelancing | Group | Business</p>
                </div>

                <div class="col-sm-2 text-center text-sm-start">
                    <p class="h5 py-2">Obszary</p>
                    <a href="#"><p>Poznań</p></a>
                    <a href="#"><p>Nowy Tomyśl</p></a>
                    <a href="#"><p>Grodzisk Wielkopolski</p></a>
                    <a href="#"><p>Buk</p></a>
                    <a href="#"><p>Zbąszyń</p></a>
                    <a href="#"><p>Leszno</p></a>
                </div>

                <div class="col-sm-2 text-center text-sm-start">
                    <p class="h5 py-2">Kontakt</p>

                    <p><i class="fa-solid fa-envelope fa-lg" style="color: #ffffff;"></i> <a href="mailto:contact@heskor.com">contact@heskor.com</a></p>
<!--                    <p><i class="fa-solid fa-phone-volume fa-lg" style="color: #ffffff;"></i> (+48) 123 123 123</p>-->
                    <p><i class="fa-brands fa-instagram fa-lg" style="color: #ffffff;"></i> <a href="https://instagram.com/heskorcom" target="_blank">HeskorCOM</a></p>
                </div>



                <div class="col-sm-12">
                    <hr/>
                    <div class="row">
                        <div class="col-sm-6 navFooter my-3">
                            <img src="../assets/images/heskor-white.svg" class="mx-2" width="50px" alt="" />
                            <p class="my-3">Heskor © 2024 - All rights reserved</p>
                        </div>
                        <div class="col-sm-6 text-end my-4">
                            <p><a href="/privacypolicy">Polityka prywatności</a> | <a href="#">Cookies</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
<script setup>
</script>
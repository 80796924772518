<template>
    <!-- header -->
    <header>

        <div class="header">
            <video src="../assets/images/home_movie.mp4" autoplay muted loop ></video>
            <div class="header-content">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 text-center">
                            <p class="m-0 letter-spacing text-basic-color">#AI #Applications #Websites #Software #Graphics #UI</p>
                            <h1>Passion for <strong>Technology</strong></h1>
                            <hr class="w-50 m-auto"/>
                            <p class="mt-4 mb-4 w-75 m-auto ">Zbuduj z nami Twój projekt <b>który pokażesz światu!</b></p>
                            <a href="/contact"><button class="button mx-1">
                                <i class="fa-solid fa-paper-plane fa-bounce fa-lg"></i> Darmowa wycena
                            </button></a>
                            <a href="/projects"><button class="button-outline mx-1">
                                <i class="fa-solid fa-layer-group fa-lg"></i> Projekty
                            </button></a>
                        </div>
                    </div>
                    <div class="scroll-indicator-container position-absolute bottom-0 start-50 translate-middle-x">
                        <div class="container mt-auto">
                            <div class="scroll-indicator text-center mb-4">
                                <svg class="icon" fill="none" xmlns="http://www.w3.org/2000/svg" width="18" height="39" viewBox="0 0 18 39">
                                    <rect x="1" y="1" width="16" height="25" rx="8" fill="#23201D" fill-opacity=".3" stroke="var(--color, #fff)" stroke-width="2"></rect>
                                    <rect x="7.5" y="5.25" width="3" height="5.25" rx="1.5" fill="var(--color, #fff)"></rect>
                                    <path d="m11 32-2 2-2-2" stroke="var(--color, #fff)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="m11 36-2 2-2-2" stroke="var(--color, #fff)" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                                <p class="text-uppercase">Discover</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <main class="text-white">

    <!-- about us -->
    <div class="container py-sm-5">
        <div class="line-section">
            <div class="row">
                <div class="col-sm-5"><hr/></div>
                <div class="col-sm-2 text-center icon-lighting "><img src="../assets/images/icons/users-solid.svg" width="60px" alt=""></div>
                <div class="col-sm-5"><hr/></div>
            </div>
        </div>
    </div>

    <div class="about-us">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="about-us-desc py-5 text-center">
                        <h2 class="w-100">Zacznij swój projekt <strong>z Heskor</strong></h2>
                        <p class="text-basic-color">Pomożemy zbudować projekt strony internetowej lub aplikacji webowej dla Twojej firmy. Posiadamy wieloletnie
                        doświadczenie w tym co robimy na co dzień, tworzymy projekty indywidualne oraz dla przeróżnych firm lub partnerów biznesowych od 2023 roku.</p>
                        <a href="/about"><input type="button" class="button my-sm-4" value="Kim jesteśmy?"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="whatwedo">
        <div class="container">
            <hr/>
            <div class="row">
                <div class="whatwedo-container py-2 ">
                    <div class="row">
                        <div class="col-sm-4 p-3">
                            <div class="row">
                                <div class="col-sm-2 text-center my-auto">
                                    <i class="fa-solid fa-sitemap fa-2xl" style="color: #ffffff;"></i>
                                </div>
                                <div class="col-sm-10 text-center text-sm-start">
                                    <h4 class="my-2"><strong>Strony www</strong></h4>
                                    <p class="text-basic-color">Tworzymy strony internetowe oparte na systemie CMS dla mniejszych i większych Klientów.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4 p-3">
                            <div class="row">
                                <div class="col-sm-2 text-center my-auto">
                                    <i class="fa-solid fa-code fa-2xl" style="color: #ffffff;"></i>
                                </div>
                                <div class="col-sm-10 text-center text-sm-start">
                                    <h4 class="my-2"><strong>Aplikacje</strong></h4>
                                    <p class="text-basic-color">Budujemy aplikacje internetowe dla najbardziej wymagających Klientów.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4 p-3">
                            <div class="row">
                                <div class="col-sm-2 text-center my-auto">
                                    <i class="fa-solid fa-person-chalkboard fa-2xl" style="color: #ffffff;"></i>
                                </div>
                                <div class="col-sm-10 text-center text-sm-start">
                                    <h4 class="my-2"><strong>Automatyzacja AI</strong></h4>
                                    <p class="text-basic-color">Wdrażamy i rozwijamy najnowsze technologie oparte o AI.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4 p-3">
                            <div class="row">
                                <div class="col-sm-2 text-center my-auto">
                                    <i class="fa-solid fa-user-astronaut fa-2xl" style="color: #ffffff;"></i>
                                </div>
                                <div class="col-sm-10 text-center text-sm-start">
                                    <h4 class="my-2"><strong>UX/UI</strong></h4>
                                    <p class="text-basic-color">Przeprowadzimy dla Ciebie audyt UX lub stworzymy profesjonalny UI.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4 p-3">
                            <div class="row">
                                <div class="col-sm-2 text-center my-auto">
                                    <i class="fa-solid fa-paintbrush fa-2xl" style="color: #ffffff;"></i>
                                </div>
                                <div class="col-sm-10 text-center text-sm-start">
                                    <h4 class="my-2"><strong>Grafika</strong></h4>
                                    <p class="text-basic-color">Tworzymy grafikę komputerową do różnego rodzaju reklam oraz publikacji. </p>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-4 p-sm-3">
                            <div class="row">
                                <div class="col-sm-2 text-center my-auto">
                                    <i class="fa-solid fa-headset fa-2xl" style="color: #ffffff;"></i>
                                </div>
                                <div class="col-sm-10 text-center text-sm-start">
                                    <h4 class="my-2"><strong>Szkolenia IT</strong></h4>
                                    <p class="text-basic-color">Prowadzimy szkolenia z obsługi naszych aplikacji.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>

    <!-- Offerts -->
    <div class="container py-sm-5">
        <div class="line-section">
            <div class="row">
                <div class="col-sm-5"><hr/></div>
                <div class="col-sm-2 text-center icon-lighting"><img src="../assets/images/icons/server-solid.svg" width="60px" alt=""></div>
                <div class="col-sm-5"><hr/></div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-sm-4 py-5">
                <div class="card-outline-basic px-5 py-5 h-100">
                    <div class="card-title text-center">
                        <i class="fa-solid fa-rocket fa-2xl" style="color: #267edb;"></i>
                        <h3 class="my-3">Cloud</h3>
                        <p class="text-center my-0 text-basic-color">Oferta dla mniejszych Firm</p>
                        <p>(Strona www oparta o system CMS)</p>

                    </div>
                    <div class="card-desc">
                        <ul>
                            <li class="py-2"><img src="../assets/images/icons/check-solid.svg" width="18px" alt=""> Szkolenie z obsługi projektu</li>
                            <li class="py-2"><img src="../assets/images/icons/check-solid.svg" width="18px" alt=""> Wersja responsywna (mobilna)</li>
                            <li class="py-2"><img src="../assets/images/icons/check-solid.svg" width="18px" alt=""> x1 Wizualizacja strony internetowej</li>
                            <li class="py-2"><img src="../assets/images/icons/check-solid.svg" width="18px" alt=""> x5 Podstron</li>
                            <li class="py-2"><img src="../assets/images/icons/check-solid.svg" width="18px" alt=""> Wsparcie i pomoc techniczna</li>
                        </ul>
                    </div>
                    <div class="card-inputs text-center py-4">
                        <a href="/contact"><input type="button" class="button my-2 p-3 w-100" value="Wyślij zapytanie o ofertę!"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-4 py-4">
                <div class="card-outline-basic px-4 py-3 h-100">
                    <div class="card-title text-center p-4">
                        <i class="fa-solid fa-user-astronaut fa-bounce fa-2xl" style="color: #267edb;"></i>
                        <h3 class="my-3">Cloud<strong>+</strong></h3>
                        <p class="text-center my-0 text-basic-color">Oferta dla średnich-większych Firm</p>
                        <p>(CMS / Indywidualny system)</p>
                    </div>
                    <div class="card-desc">
                        <ul>
                            <li class="py-2"><img src="../assets/images/icons/check-solid.svg" width="18px" alt=""> Szkolenie z obsługi projektu</li>
                            <li class="py-2"><img src="../assets/images/icons/check-solid.svg" width="18px" alt=""> Wersja responsywna (mobilna)</li>
                            <li class="py-2"><img src="../assets/images/icons/check-solid.svg" width="18px" alt=""> x1 Wizualizacja strony internetowej</li>
                            <li class="py-2"><img src="../assets/images/icons/check-solid.svg" width="18px" alt=""> x10 Podstron</li>
                            <li class="py-2"><img src="../assets/images/icons/check-solid.svg" width="18px" alt=""> Wsparcie i pomoc techniczna</li>
                        </ul>
                    </div>
                    <div class="card-inputs text-center py-4">
                        <a href="/contact"><input type="button" class="button my-2 p-3 w-100" value='Wyślij zapytanie o ofertę plus!'></a>
                    </div>
                </div>
            </div>


            <div class="col-sm-4 py-5">
                <div class="card-outline-basic px-5 py-5 h-100">
                    <div class="card-title text-center">
                        <i class="fa-solid fa-meteor fa-2xl" style="color: #267edb;"></i>
                        <h3 class="my-3">Cloud<strong>+ PRO</strong></h3>
                        <p class="text-center my-0 text-basic-color">Oferta dla dużych przedsiębiorstw</p>
                        <p>(CMS / Indywidualny system)</p>
                    </div>
                    <div class="card-desc">
                        <ul>
                            <li class="py-2"><img src="../assets/images/icons/check-solid.svg" width="18px" alt=""> Wszystko z poprzednich pakietów</li>
                            <li class="py-2"><img src="../assets/images/icons/check-solid.svg" width="18px" alt=""> Szkolenie dla Firmy (z projektu)</li>
                            <li class="py-2"><img src="../assets/images/icons/check-solid.svg" width="18px" alt=""> x2 Wizualizacja projektu (optional)</li>
                            <li class="py-2"><img src="../assets/images/icons/check-solid.svg" width="18px" alt=""> Nielimitowana ilość podstron</li>
                            <li class="py-2"><img src="../assets/images/icons/check-solid.svg" width="18px" alt=""> Wsparcie i pomoc techniczna (VIP)</li>
                        </ul>
                    </div>
                    <div class="card-inputs text-center py-4">
                        <a href="/contact"><input type="button" class="button my-2 p-3 w-100" value="Wyślij zapytanie o ofertę PRO!"></a>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="container padding-box">
         <div class="col-sm-12 text-center">
             <p class="h2">Porozmawiajmy o Twoim projekcie!</p>
             <p class="text-basic-color">Prześlij nam swój plan projektu, a my dokładnie go przeanalizujemy i wycenimy.</p>
             <a href="/contact"><button class="button mx-1 my-4">
                    <i class="fa-solid fa-paper-plane fa-bounce fa-lg"></i> Darmowa wycena!
             </button></a>
         </div>
    </div>

    </main>

</template>

<script>

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Home',
    beforeRouteEnter(to, from, next) {
        // Odczytaj metadane z to.meta
        const { title, desc } = to.meta;

        // Ustaw metadane w nagłówkach strony
        document.title = title;
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute('content', desc);
        }

        next();
    },
}
</script>